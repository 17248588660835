<template>
  <v-form ref="form" :valid="valid">
    <v-row>
      <v-col md="4">
        <v-text-field
          placeholder="القيمة"
          label="القيمة"
          v-model="groupForm.value"
          :rules="[(v) => !!v || 'value is required']"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10">
        <v-overlay :value="submitted">
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular> </v-overlay
      ></v-col>
      <v-col cols="12" md="2">
        <v-btn color="success" @click="submit">
          إرسال
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="answerHeader"
          :items="answers"
          :items-per-page="5"
          class="elevation-1 "
        >
          <template v-slot:top>
            <v-toolbar flat class="mb-7">
              <!-- delete item -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >Are you sure you want to delete this user?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deleteItemConfirm()"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template
            v-slot:item.actions="{ item }"
            style="textAlign:right !important"
          >
            <v-icon
              color="primary"
              small
              class="mr-2"
              @click="editAnswer(item)"
            >
              mdi-pencil
            </v-icon>

            <v-icon
              color="error"
              small
              class="mr-2"
              @click="deleteAnswer(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["formTitle", "dialog", "group_id"],
  data() {
    return {
      loadingTypes: false,
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      dialogDelete: false,
      answers: [],
      answerHeader: [
        {
          text: "text",
          value: "value",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      error: "",
      groupForm: {
        id: null,
        value: null,
        group_id: null,
      },
      editorOption: {
        // some quill options
      },
      record: null,
      textRules: [(v) => !!v || "field is required"],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    deleteAnswer(record) {
      this.record = record;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      try {
        this.loading = true;
        await ApiService.delete(`items/groups_answers_value/${this.record.id}`);
        this.loadData();
        this.loading = false;
        this.closeDelete();

        alert("Product deleted successfully");
        this.loadData();
      } catch (error) {
        console.log(error);
      }
    },
    closeDelete() {
      this.record = null;
      this.dialogDelete = false;
    },
    editAnswer(item) {
      Object.keys(this.groupForm).forEach((element) => {
        if (item[element]) {
          if (typeof item[element] == "object") {
            this.groupForm[element] = item[element].id;
          } else {
            this.groupForm[element] = item[element];
          }
        }
      });
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;
          if (this.groupForm.id) {
            const groupReq = await ApiService.update(
              "items/groups_answers_value",
              this.groupForm.id,
              this.groupForm
            );
          } else {
            this.groupForm.group_id = this.group_id;
            console.log(this.groupForm);
            const groupReq = await ApiService.post(
              "items/groups_answers_value",
              this.groupForm
            );
          }
          this.submitted = false;
          this.loadData();
          // this.$emit("formSubmitted", "categories ok");
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", this.group_id, msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    close() {
      this.$emit("closeDialog");
    },
    async loadData() {
      console.log("load", this.group_id);
      this.loading = true;
      const groupReq = await ApiService.get(
        `items/groups_answers_value?fields=id,value,group_id&filter[group_id]=${this.group_id}`
      );
      this.answers = groupReq.data.data;
    },
  },

  async mounted() {
    // this.record && Object.assign(this.groupForm, this.record);
    // Object.assign(this.initialPayload, this.record);
  },
};
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
