<template>
  <v-form ref="form" :valid="valid">
    <v-row>
      <v-col md="12">
        <v-text-field
          placeholder="العنوان"
          label="العنوان"
          v-model="groupForm.title"
          :rules="[(v) => !!v || 'title is required']"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10">
        <v-overlay :value="submitted">
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular> </v-overlay
      ></v-col>
      <v-col cols="12" md="2">
        <v-btn color="success" @click="submit">
          إرسال
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ApiService from "@/services/api.service";
// import { mapActions } from "vuex";

export default {
  props: ["formTitle", "record", "dialog", "course_id"],
  data() {
    return {
      loadingTypes: false,
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      groupForm: {
        id: null,
        course_id: null,
        title: null,
      },
      editorOption: {
        // some quill options
      },
      textRules: [(v) => !!v || "field is required"],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;
          let groupReq;
          console.log(this.groupForm);
          this.groupForm.course_id = this.course_id;
          console.log(this.groupForm);
          if (this.record) {
            groupReq = await ApiService.update(
              "items/groups",
              this.groupForm.id,
              this.groupForm
            );
          } else {
            groupReq = await ApiService.post("items/groups", this.groupForm);
            this.groupForm.id = groupReq.data.data.id;
          }
          this.submitted = false;
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", groupReq.data.data.id, msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    close() {
      this.$emit("closeDialog");
    },
  },

  async mounted() {
    if (this.record) {
      Object.keys(this.groupForm).forEach((element) => {
        if (this.record[element]) {
          if (typeof this.record[element] == "object") {
            this.groupForm[element] = this.record[element].id;
          } else {
            this.groupForm[element] = this.record[element];
          }
        }
      });
      console.log(this.groupForm);
    }
  },
};
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
